import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LazyImage from 'components/shared/LazyImage';
import Button from 'components/shared/Button';
import { HeadingOne } from 'components/shared/Headings';
import scrollToSection from 'utils/scrollToSection';

const Section = styled.section`
  position: relative;
  width: 100%;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 50px;
    background: ${({ theme }) => theme.colors.white};
    ${({ theme }) => theme.mq.lg} {
      height: 100px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mq.xxl} {
    flex-direction: row;
  }
`;

const ContentWrapper = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-basis: 50%;
  color: #fff;
  padding: 50px 20px 30px;
  ${({ theme }) => theme.mq.s} {
    padding: 60px 40px 40px;
  }
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
    justify-content: space-between;
  }
  &:before {
    content: '';
    position: absolute;
    left: 30px;
    top: 0;
    width: 0;
    height: 0;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-top: 24px solid #fff;
    ${({ theme }) => theme.mq.s} {
      left: 40px;
      border-width: 34px;
    }
  }
  ${({ theme }) => theme.mq.xxl} {
    padding: 80px 50px 50px !important;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
  ${({ theme }) => theme.mq.xxl} {
    max-width: 400px;
    justify-content: flex-end;
  }
`;

const Description = styled.p`
  font-weight: ${({ theme }) => theme.light};
  font-size: 2.2rem;
  ${({ theme }) => theme.mq.s} {
    font-size: 2.8rem;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 3.3rem;
  }
`;

const ButtonWrapper = styled.div`
  margin: 25px 0;
  ${({ theme }) => theme.mq.s} {
    margin: 40px 0;
  }
`;

const HeadingWrapper = styled.div`
  margin-top: 10px;
  ${({ theme }) => theme.mq.s} {
    margin-top: 20px;
  }
`;

const Author = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  ${({ theme }) => theme.mq.s} {
    flex-direction: row;
  }
  ${({ theme }) => theme.mq.lg} {
    margin-left: 30px;
    flex-direction: column;
    justify-content: center;
    margin-top: auto;
  }
`;

const AuthorName = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.fonts.specialFont};
  font-size: 8rem;
  transform: rotate(-15deg);
  line-height: 0.6;
  order: 1;
  margin-top: 20px;
  margin-bottom: 20px;
  ${({ theme }) => theme.mq.s} {
    font-size: 8.8rem;
    margin-top: 0;
    margin-left: 20px;
    margin-bottom: 0;
  }
  ${({ theme }) => theme.mq.lg} {
    order: 0;
    margin-left: 0;
    margin-bottom: 50px;
    transform: rotate(-20deg);
  }
`;

const AuthorImage = styled.div`
  min-width: 160px;
  max-width: 160px;
  min-height: 160px;
  max-height: 160px;
  ${({ theme }) => theme.mq.md} {
    min-width: 230px;
    max-width: 230px;
    min-height: 230px;
    max-height: 230px;
  }
`;

const Introduction = ({ contents }) => {
  return (
    <Section>
      <Wrapper>
        {contents.map(
          ({
            background,
            description,
            buttonText,
            buttonLink,
            title,
            cookImg,
            cookName,
          }) => (
            <ContentWrapper key={title}>
              <ImageWrapper>
                <LazyImage
                  src={background}
                  alt="background"
                  cover
                  objectPosition="80% 50%"
                />
              </ImageWrapper>
              <Column>
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
                <ButtonWrapper>
                  <Button onClick={() => scrollToSection(buttonLink)}>
                    {buttonText}
                  </Button>
                </ButtonWrapper>
                <HeadingWrapper>
                  <HeadingOne dangerouslySetInnerHTML={{ __html: title }} />
                </HeadingWrapper>
              </Column>
              <Author>
                <AuthorName>{cookName}</AuthorName>
                <AuthorImage>
                  <LazyImage src={cookImg} alt={cookName} />
                </AuthorImage>
              </Author>
            </ContentWrapper>
          )
        )}
      </Wrapper>
    </Section>
  );
};

Introduction.propTypes = {
  contents: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Introduction;
