/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import Content from 'components/shared/Content';
import Button from 'components/shared/Button';
import LazyImage from 'components/shared/LazyImage';
import Icon from 'components/shared/Icon';
import cartIcon from 'assets/icons/koszyk.svg';
import plusIcon from 'assets/icons/plus.png';
import arrowIcon from 'assets/icons/arrow-right.svg';
import useMedia from 'hooks/useMedia';

const Section = styled.section`
  position: relative;
  width: 100%;
`;

const Intro = styled.div`
  background: #cdc5ae;
  padding: 40px 0 50px;
  ${({ theme }) => theme.mq.s} {
    padding: 60px 0;
  }
`;

const Heading = styled.div`
  position: relative;
  font-weight: ${({ theme }) => theme.light};
  font-size: 1.8rem;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 4px;
  line-height: 1.4;
  br {
    display: none;
  }
  ${({ theme }) => theme.mq.xs} {
    font-size: 2.2rem;
  }
  ${({ theme }) => theme.mq.s} {
    font-size: 2.6rem;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 3rem;
    br {
      display: initial;
    }
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -25px;
    height: 2px;
    background: #000;
    width: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
  }
`;

const Wrapper = styled.div`
  background: #b8b29a;
  padding: 40px 0 50px;
  ${({ theme }) => theme.mq.s} {
    padding: 60px 0;
  }
`;

const Description = styled.p`
  font-size: 1.7rem;
  font-weight: ${({ theme }) => theme.light};
  text-align: center;
  line-height: 1.5;
  a {
    color: ${({ theme }) => theme.colors.dark};
    transition: 0.3s;
    &:hover {
      color: ${({ theme }) => theme.colors.darkGreen};
    }
  }
  ${({ theme }) => theme.mq.s} {
    font-size: 2rem;
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: 2.4rem;
  }
`;

const ProductPackage = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0 20px;
  ${({ theme }) => theme.mq.lg} {
    margin: 70px 0 40px;
  }
  & > div {
    max-width: 1260px;
  }
  ${Heading} {
    font-weight: ${({ theme }) => theme.regular};
    &:after {
      bottom: -15px;
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  padding: 0 30px;
  ${({ theme }) => theme.mq.md} {
    justify-content: flex-end;
    flex-direction: row;
    margin-top: 60px;
  }
`;

const ImageInnerWrapper = styled.div`
  width: 100%;
  max-width: 380px;
  ${({ theme }) => theme.mq.xxxl} {
    max-width: 450px;
  }
`;

const OrderButtonWrapper = styled.div`
  margin: 20px 0 0 0;
  ${({ theme }) => theme.mq.md} {
    margin: 0 0 0 30px;
  }
`;

const OrderButton = styled.div`
  display: flex;
  align-items: center;
  div {
    min-width: 46px;
    min-height: 46px;
    max-width: 46px;
    max-height: 46px;
    ${({ theme }) => theme.mq.s} {
      min-width: 60px;
      min-height: 60px;
    }
  }
  button,
  a {
    margin-left: 15px;
    background: ${({ theme }) => theme.colors.orange};
    color: #fff;
    white-space: nowrap;
    ${({ theme }) => theme.mq.s} {
      margin-left: 25px;
    }
  }
`;

const ProductsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
  width: 100%;
  max-width: max-content;
  ${({ theme }) => theme.mq.lg} {
    padding-bottom: 150px;
  }
`;

const IndexNumber = styled.figure`
  min-width: 53px;
  min-height: 53px;
  max-width: 53px;
  max-height: 53px;
  background: #000;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  padding-top: 5px;
  font-weight: ${({ theme }) => theme.bold};
  transition: 0.3s;
`;

const Product = styled.div`
  position: relative;
  width: 170px;
  height: 240px;
  z-index: 3;
  ${({ theme }) => theme.mq.xxxl} {
    width: 220px;
    height: 310px;
  }
  ${({ last }) =>
    last &&
    css`
      display: none;
      ${({ theme }) => theme.mq.lg} {
        display: block;
        cursor: default;
        pointer-events: none;
        width: 134px;
        height: 186px;
        margin-left: -40px;
        z-index: 2;
      }
      ${({ theme }) => theme.mq.xxxl} {
        width: 170px;
        height: 240px;
        margin-left: -50px;
      }
    `}
`;

const ShadowWrapper = styled.div`
  display: none;
  ${({ theme }) => theme.mq.lg} {
    display: block;
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    height: 192px;
    z-index: 1;
  }
  ${({ theme }) => theme.mq.xxxl} {
    bottom: 10px;
  }
`;

const IndexNumberWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 70%;
  transform: translateX(-70%);
  ${({ theme }) => theme.mq.md} {
    bottom: -65px;
    left: 50%;
    transform: translateX(-50%);
  }
  ${({ theme }) => theme.mq.lg} {
    bottom: -90px;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.mq.xxl} {
    flex-direction: row;
  }
`;

const Recipes = styled.div`
  position: relative;
  min-width: 220px;
  height: 224px;
  order: -1;
  margin: 30px 0 120px 30px;
  ${({ theme }) => theme.mq.s} {
    min-width: 277px;
    height: 282px;
  }
  ${({ theme }) => theme.mq.md} {
    margin-top: 0;
  }
  ${({ theme }) => theme.mq.xxl} {
    margin-left: 50px;
    order: 1;
  }
  ${({ theme }) => theme.mq.xxxl} {
    min-width: 357px;
    height: 364px;
  }
`;

const PlusButton = styled.button`
  position: absolute;
  bottom: -90px;
  left: 50%;
  transform: translateX(-50%);
  width: 66px;
  height: 66px;
  background: transparent;
  padding: 0;
  z-index: 4;
  ${({ theme }) => theme.mq.xxl} {
    top: 40%;
    transform: translateY(-40%);
    bottom: auto;
    left: -40px;
  }
  ${({ theme }) => theme.mq.xxxl} {
    width: 73px;
    height: 73px;
  }
`;

const StyledContent = styled(Content)`
  max-width: 1800px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0 20px;
  ${({ theme }) => theme.mq.s} {
    margin: 60px 0 30px;
  }
`;

const SwiperWrapper = styled(Content)`
  position: relative;
  cursor: grab;
  margin-bottom: 30px;
  max-width: 500px;
`;

const SlideImage = styled.div`
  position: relative;
  height: 280px;
  ${({ theme }) => theme.mq.xs} {
    height: 300px;
  }
`;

const ArrowRight = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  border: 2px solid #000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #b8b29a;
  padding: 10px 0;
  cursor: pointer;
  z-index: 2;
  @media (max-width: 360px) {
    right: 10px;
    width: 44px;
    height: 44px;
  }
`;

const ArrowLeft = styled(ArrowRight)`
  right: auto !important;
  left: 15px;
  transform: translateY(-50%) rotate(180deg);
  @media (max-width: 360px) {
    left: 10px;
  }
`;

const settings = {
  loop: true,
  slidesPerView: 'auto',
};

const Products = ({
  intro,
  description,
  productPackage,
  products,
  productShadow,
  recipesImg,
}) => {
  const matchesMD = useMedia('md');

  const swiperRef = useRef(null);

  const goNext = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <Section>
      <Intro>
        <Content>
          <Heading dangerouslySetInnerHTML={{ __html: intro }} />
        </Content>
      </Intro>
      <Wrapper>
        <Content>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </Content>
        <ProductPackage>
          <Content>
            <Heading>{productPackage.heading}</Heading>
            <ImageWrapper>
              <ImageInnerWrapper>
                <LazyImage
                  src={productPackage.image}
                  alt={productPackage.heading}
                />
              </ImageInnerWrapper>
              <OrderButtonWrapper>
                <OrderButton>
                  <Icon src={cartIcon} alt="koszyk" size={60} />
                  <Button
                    as="a"
                    href="https://www.aledobre.pl/29894,Zestaw_mak_do_domowego_wypieku_chleba_pizzy_i_focacci_Ashanti_ToTa.html"
                    target="_blank"
                  >
                    Zamów teraz
                  </Button>
                </OrderButton>
              </OrderButtonWrapper>
            </ImageWrapper>
          </Content>
        </ProductPackage>
        <InnerWrapper>
          {matchesMD ? (
            <ProductsWrapper>
              {products.map(({ title, image, number }, index) => (
                <Product last={index === products.length - 1} key={index}>
                  <LazyImage src={image} alt={title} />
                  {index !== products.length - 1 && (
                    <IndexNumberWrapper>
                      <IndexNumber>{number}x</IndexNumber>
                    </IndexNumberWrapper>
                  )}
                </Product>
              ))}
              <ShadowWrapper>
                <LazyImage src={productShadow} alt="shadow" />
              </ShadowWrapper>
            </ProductsWrapper>
          ) : (
            <SwiperWrapper>
              <Swiper ref={swiperRef} {...settings}>
                {products
                  .slice(0, products.length - 1)
                  .map(({ image, title, number }, index) => (
                    <SwiperSlide key={index}>
                      <SlideImage>
                        <LazyImage src={image} alt={title} />
                        <IndexNumberWrapper>
                          <IndexNumber>{number}x</IndexNumber>
                        </IndexNumberWrapper>
                      </SlideImage>
                    </SwiperSlide>
                  ))}
              </Swiper>
              <ArrowLeft onClick={goPrev} aria-label="slide left">
                <Icon src={arrowIcon} alt="arrow right" full />
              </ArrowLeft>
              <ArrowRight onClick={goNext} aria-label="slide right">
                <Icon src={arrowIcon} alt="arrow right" full />
              </ArrowRight>
            </SwiperWrapper>
          )}
          <Recipes>
            <PlusButton>
              <Icon src={plusIcon} alt="pokaż przepisy" full />
            </PlusButton>
            <LazyImage src={recipesImg} alt="przepisy" />
          </Recipes>
        </InnerWrapper>
        <StyledContent>
          <ButtonWrapper>
            <OrderButton>
              <Icon src={cartIcon} alt="koszyk" size={60} />
              <Button
                as="a"
                href="https://www.aledobre.pl/29894,Zestaw_mak_do_domowego_wypieku_chleba_pizzy_i_focacci_Ashanti_ToTa.html"
                target="_blank"
              >
                Zamów teraz
              </Button>
            </OrderButton>
          </ButtonWrapper>
        </StyledContent>
      </Wrapper>
    </Section>
  );
};

Products.propTypes = {
  intro: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  showCookBook: PropTypes.func.isRequired,
  productPackage: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  productShadow: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
  recipesImg: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
};

export default Products;
