import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: ${({ height }) => height};
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const Spinner = styled.figure`
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
  & > div {
    position: absolute;
    width: 64px;
    height: 64px;
    border: 8px solid ${({ theme }) => theme.colors.gold};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ theme }) => theme.colors.gold} transparent transparent
      transparent;
    animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

const Loader = ({ height }) => (
  <Wrapper height={height}>
    <Spinner>
      <div />
      <div />
      <div />
      <div />
    </Spinner>
  </Wrapper>
);

Loader.propTypes = {
  height: PropTypes.string,
};

Loader.defaultProps = {
  height: '100%',
};

export default Loader;
