import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LazyImage from 'components/shared/LazyImage';
import Icon from 'components/shared/Icon';
import { HeadingOne } from 'components/shared/Headings';
import arrowDown from 'assets/icons/arrow-down.svg';

const Section = styled.section`
  position: relative;
  width: 100%;
`;

const Wrapper = styled.article`
  position: relative;
  display: flex;
  align-items: center;
  padding: 60px 20px 200px;
  ${({ theme }) => theme.mq.s} {
    padding: 100px 40px 240px;
  }
  ${({ theme }) => theme.mq.xl} {
    padding: 100px 40px;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding: 140px 50px;
  }
  &:before {
    content: '';
    position: absolute;
    left: 30px;
    top: 0;
    width: 0;
    height: 0;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-top: 24px solid #fff;
    ${({ theme }) => theme.mq.s} {
      left: 40px;
      border-width: 34px;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  max-width: 800px;
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
`;

const Description = styled.p`
  font-weight: ${({ theme }) => theme.light};
  font-size: 2.2rem;
  margin: 20px 0;
  ${({ theme }) => theme.mq.s} {
    font-size: 2.8rem;
    margin: 30px 0;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 3.3rem;
    margin: 40px 0;
  }
`;

const Author = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: -175px;
  left: 50%;
  transform: translateX(-50%);
  ${({ theme }) => theme.mq.md} {
    top: -220px;
  }
  ${({ theme }) => theme.mq.xl} {
    top: -250px;
    left: 95%;
    transform: translateX(-95%);
  }
  ${({ theme }) => theme.mq.xxxl} {
    left: 85%;
    transform: translateX(-85%);
  }
`;

const AuthorName = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.fonts.specialFont};
  font-size: 8rem;
  transform: rotate(-15deg);
  line-height: 0.6;
  color: #fff;
  white-space: nowrap;
  margin-bottom: 25px;
  ${({ theme }) => theme.mq.md} {
    font-size: 8.8rem;
    transform: rotate(-20deg);
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: 10.3rem;
    margin-bottom: 45px;
  }
`;

const AuthorImage = styled.div`
  min-width: 140px;
  max-width: 140px;
  min-height: 140px;
  max-height: 140px;
  ${({ theme }) => theme.mq.md} {
    min-width: 180px;
    max-width: 180px;
    min-height: 180px;
    max-height: 180px;
  }
  ${({ theme }) => theme.mq.xl} {
    min-width: 260px;
    max-width: 260px;
    min-height: 260px;
    max-height: 260px;
  }
`;

const Footer = styled.div`
  position: relative;
  padding: 60px 20px 80px;
  width: 100%;
  background: ${({ theme }) => theme.colors.darkGreen};
  ${({ theme }) => theme.mq.lg} {
    padding: 60px 20px 120px;
  }
`;

const FooterText = styled.p`
  position: relative;
  display: block;
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.light};
  text-transform: uppercase;
  letter-spacing: 6px;
  color: #fff;
  text-align: center;
  line-height: 1.4;
  ${({ theme }) => theme.mq.s} {
    font-size: 2.4rem;
    letter-spacing: 10px;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 3rem;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  bottom: -75px;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 991px) {
    bottom: -60px;
    & > div {
      width: 50px !important;
      height: 50px !important;
    }
  }
`;

const About = ({
  background,
  title,
  description,
  autorImg,
  autorName,
  footer,
}) => {
  return (
    <Section>
      <Wrapper>
        <ImageWrapper>
          <LazyImage src={background} alt="baking" cover />
        </ImageWrapper>
        <Column>
          <HeadingOne dangerouslySetInnerHTML={{ __html: title }} />
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </Column>
      </Wrapper>
      <Footer>
        <Author>
          <AuthorName>{autorName}</AuthorName>
          <AuthorImage>
            <LazyImage src={autorImg} alt={autorName} />
          </AuthorImage>
        </Author>
        <FooterText>
          {footer}
          <IconWrapper>
            <Icon src={arrowDown} alt="arrow-down" auto />
          </IconWrapper>
        </FooterText>
      </Footer>
    </Section>
  );
};

About.propTypes = {
  background: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  autorImg: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  autorName: PropTypes.string.isRequired,
  footer: PropTypes.string.isRequired,
};

export default About;
