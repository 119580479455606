import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import Icon from 'components/shared/Icon';
import LazyImage from 'components/shared/LazyImage';
import websiteIcon from 'assets/icons/website.svg';
import wecanLogo from 'assets/icons/wecan.svg';

const StyledSection = styled(Section)`
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.colors.darkGreen};
  color: #fff;
  ${({ theme }) => theme.mq.lg} {
    padding: 100px 0;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 400px;
    background: #cdc5ae;
    ${({ theme }) => theme.mq.s} {
      height: 500px;
    }
  }
`;

const Heading = styled.h3`
  position: relative;
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.bold};
  letter-spacing: 2px;
  text-align: center;
  line-height: 1.4;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gold};
  ${({ theme }) => theme.mq.s} {
    font-size: 2.4rem;
    letter-spacing: 4px;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 3rem;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -15px;
    height: 2px;
    background: ${({ theme }) => theme.colors.gold};
    width: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
    ${({ theme }) => theme.mq.s} {
      bottom: -25px;
    }
  }
`;

const HeadingWrapper = styled.div`
  margin: 0 0 30px;
  ${({ theme }) => theme.mq.s} {
    margin: 0 0 50px;
  }
  ${({ theme }) => theme.mq.lg} {
    margin: 0 0 80px;
  }
`;

const StyledContent = styled(Content)`
  max-width: 1800px;
`;

const AuthorsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  br {
    display: none;
  }
  ${({ theme }) => theme.mq.xl} {
    br {
      display: initial;
    }
    flex-direction: row;
    justify-content: space-between;
    align-items: initial;
  }
`;

const AuthorCard = styled.article`
  display: flex;
  flex-direction: column;
  position: relative;
  background: #1c2514;
  width: 100%;
  max-width: 820px;
  &:not(:last-child) {
    margin: 0 0 30px 0;
    ${({ theme }) => theme.mq.xl} {
      margin: 0 20px 0 0;
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 240px;
  pointer-events: none;
  ${({ theme }) => theme.mq.md} {
    height: 360px;
  }
  &:before {
    content: '';
    position: absolute;
    left: 30px;
    top: 0;
    width: 0;
    height: 0;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-top: 24px solid ${({ theme }) => theme.colors.darkGreen};
    z-index: 1;
    ${({ theme }) => theme.mq.s} {
      left: 40px;
      border-width: 34px;
    }
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  z-index: 1;
  ${({ theme }) => theme.mq.s} {
    width: 300px;
  }
`;

const ContentWrapper = styled.div`
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  ${({ theme }) => theme.mq.lg} {
    padding: 40px 25px;
  }
`;

const Text = styled.p`
  font-size: 1.7rem;
  font-weight: ${({ theme }) => theme.light};
  padding: 15px 0 25px;
  letter-spacing: 2px;
  ${({ theme }) => theme.mq.s} {
    padding: 25px 0;
    font-size: 2rem;
    letter-spacing: 4px;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 2.15rem;
  }
`;

const HighlightedText = styled(Text)`
  color: ${({ theme }) => theme.colors.gold};
  font-weight: ${({ theme }) => theme.medium};
  padding: 0;
`;

const LinkWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: auto;
`;

const WebsiteLink = styled.a`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: max-content;
  color: #fff;
  text-decoration: none;
  font-weight: ${({ theme }) => theme.light};
  font-size: 2rem;
  letter-spacing: 2px;
  transition: 0.3s;
  ${({ theme }) => theme.mq.s} {
    font-size: 2.3rem;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.gold100};
  }
  span {
    margin-top: 15px;
  }
`;

const FooterText = styled(Heading)`
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  justify-content: center;
  align-items: center;
  color: #000;
  z-index: 1;
  text-transform: none;
  text-decoration: none;
  margin-top: 60px;
  & > div {
    margin-top: 15px;
  }
  ${({ theme }) => theme.mq.xs} {
    flex-direction: row;
    & > div {
      margin-top: 0;
      margin-left: 10px;
    }
  }
  ${({ theme }) => theme.mq.md} {
    margin-top: 80px;
  }
`;

const Footer = ({ authors, authorsHeading }) => {
  return (
    <StyledSection as="footer">
      <Content>
        <HeadingWrapper>
          <Heading dangerouslySetInnerHTML={{ __html: authorsHeading }} />
        </HeadingWrapper>
      </Content>
      <StyledContent>
        <AuthorsWrapper>
          {authors.map(
            ({ background, logo, heading, text, url, urlText }, index) => (
              <AuthorCard key={heading}>
                <ImageWrapper>
                  <LazyImage
                    src={background}
                    alt={`background ${index + 1}`}
                    cover
                  />
                  <LogoWrapper>
                    <LazyImage src={logo} alt={heading} />
                  </LogoWrapper>
                </ImageWrapper>
                <ContentWrapper>
                  <HighlightedText
                    dangerouslySetInnerHTML={{ __html: heading }}
                  />
                  <Text dangerouslySetInnerHTML={{ __html: text }} />
                  <LinkWrapper>
                    <WebsiteLink href={url} target="_blank">
                      <Icon src={websiteIcon} alt="website icon" size={42} />
                      <span>{urlText}</span>
                    </WebsiteLink>
                  </LinkWrapper>
                </ContentWrapper>
              </AuthorCard>
            )
          )}
        </AuthorsWrapper>
        <FooterText href="https://www.wecan.pl" target="_blank" as="a">
          <span>
            Akcję koordynuje <b>www.wecan.pl</b>
          </span>
          <Icon src={wecanLogo} alt="we can logo" auto />
        </FooterText>
      </StyledContent>
    </StyledSection>
  );
};

Footer.propTypes = {
  authors: PropTypes.arrayOf(PropTypes.object).isRequired,
  authorsHeading: PropTypes.string.isRequired,
};

export default Footer;
