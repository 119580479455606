import React from 'react';
import styled from 'styled-components';
import Content from 'components/shared/Content';

const Wrapper = styled.header`
  position: relative;
  width: 100%;
  height: 360px;
  display: flex;
  align-items: center;
  text-align: center;
  ${({ theme }) => theme.mq.s} {
    height: 400px;
  }
  ${({ theme }) => theme.mq.lg} {
    height: 500px;
  }
`;

const Title = styled.h1`
  font-size: 4.6rem;
  font-weight: ${({ theme }) => theme.bold};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gold};
  span {
    font-size: 2.6rem;
  }
  ${({ theme }) => theme.mq.s} {
    font-size: 6rem;
    span {
      font-size: 2.9rem;
    }
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 8rem;
    span {
      font-size: 3.8rem;
    }
  }
`;

const Heading = styled.span`
  display: block;
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.light};
  text-transform: uppercase;
  color: #000;
  letter-spacing: 6px;
  margin-top: 15px;
  ${({ theme }) => theme.mq.s} {
    font-size: 2.4rem;
    letter-spacing: 10px;
  }
  ${({ theme }) => theme.mq.lg} {
    margin-top: 20px;
    font-size: 3rem;
  }
`;

const Header = () => {
  return (
    <Wrapper>
      <Content>
        <Title>
          Tota! <span>&</span> Ashanti
        </Title>
        <Heading>Prezentują:</Heading>
      </Content>
    </Wrapper>
  );
};

export default Header;
