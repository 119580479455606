import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from 'components/shared/SEO';
import Header from 'components/Header/Header';
import Introduction from 'components/Introduction/Introduction';
import About from 'components/About/About';
import Videos from 'components/Videos/Videos';
import Products from 'components/Products/Products';
import Footer from 'components/Footer/Footer';

const IndexPage = ({ data }) => {
  const HOMEPAGE = data.wpgraphql.page.PageHome;

  const aboutContents = HOMEPAGE.introContents.map(
    (
      {
        introContentTitle,
        introContentDesc,
        introContentCookName,
        introContentButtonText,
        introContentCookImg,
        introContentBg,
      },
      index
    ) => ({
      background: introContentBg.imageFile.childImageSharp.fluid,
      description: introContentDesc,
      buttonText: introContentButtonText,
      buttonLink: index === 0 ? '#bread-videos' : '#pizza-videos',
      title: introContentTitle,
      cookImg: introContentCookImg.imageFile.childImageSharp.fluid,
      cookName: introContentCookName,
    })
  );

  const breadVideos =
    HOMEPAGE.videosBread &&
    HOMEPAGE.videosBread
      .filter(({ videoUrl }) => videoUrl)
      .map(({ videoTitle, videoUrl, videoThumbnail }) => ({
        thumbnail: videoThumbnail.imageFile.childImageSharp.fluid,
        title: videoTitle,
        videoURL: videoUrl,
      }));

  const pizzaVideos =
    HOMEPAGE.videosPizza &&
    HOMEPAGE.videosPizza
      .filter(({ videoUrl }) => videoUrl)
      .map(({ videoTitle, videoUrl, videoThumbnail }) => ({
        thumbnail: videoThumbnail.imageFile.childImageSharp.fluid,
        title: videoTitle,
        videoURL: videoUrl,
      }));

  const products = HOMEPAGE.productsItems.map(
    ({ productsItemName, productsItemNumber, productsItemImg }) => ({
      title: productsItemName,
      number: productsItemNumber,
      image: productsItemImg.imageFile.childImageSharp.fluid,
    })
  );

  const authors = HOMEPAGE.footerAuthors.map(
    ({
      footerAuthorHeading,
      footerAuthorPage,
      footerAuthorPageUrl,
      footerAuthorText,
      footerAuthorBg,
      footerAuthorLogo,
    }) => ({
      background: footerAuthorBg.imageFile.childImageSharp.fluid,
      logo: footerAuthorLogo.imageFile.childImageSharp.fluid,
      heading: footerAuthorHeading,
      text: footerAuthorText,
      url: footerAuthorPageUrl,
      urlText: footerAuthorPage,
    })
  );

  return (
    <>
      <SEO title="Strona główna" />
      <Header />
      <main>
        <Introduction contents={aboutContents} />
        <About
          background={HOMEPAGE.aboutBreadBg.imageFile.childImageSharp.fluid}
          title={HOMEPAGE.aboutBreadTitle}
          description={HOMEPAGE.aboutBreadText}
          autorImg={HOMEPAGE.aboutBreadCookImg.imageFile.childImageSharp.fluid}
          autorName={HOMEPAGE.aboutBreadCookName}
          footer={HOMEPAGE.aboutBreadFooter}
        />
        <Videos
          id="bread-videos"
          videos={breadVideos}
          buttonText={HOMEPAGE.videosBreadButtonText}
        />
        <About
          background={HOMEPAGE.aboutPizzaBg.imageFile.childImageSharp.fluid}
          title={HOMEPAGE.aboutPizzaTitle}
          description={HOMEPAGE.aboutPizzaText}
          autorImg={HOMEPAGE.aboutPizzaCookImg.imageFile.childImageSharp.fluid}
          autorName={HOMEPAGE.aboutPizzaCookName}
          footer={HOMEPAGE.aboutPizzaFooter}
        />
        <Videos
          id="pizza-videos"
          videos={pizzaVideos}
          buttonText={HOMEPAGE.videosPizzaButtonText}
        />
        <Products
          intro={HOMEPAGE.productsIntro}
          description={HOMEPAGE.productsDesc}
          productPackage={{
            heading: HOMEPAGE.productsPackageHeading,
            image: HOMEPAGE.productsPackageImg.imageFile.childImageSharp.fluid,
          }}
          products={products}
          productShadow={
            HOMEPAGE.productsShadow.imageFile.childImageSharp.fluid
          }
          recipesImg={
            HOMEPAGE.productsRecipesImg.imageFile.childImageSharp.fluid
          }
        />
      </main>
      <Footer
        authorsHeading={HOMEPAGE.footerHeading}
        description={HOMEPAGE.footerText}
        authors={authors}
      />
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query queryHomePage {
    wpgraphql {
      page(id: "/strona-glowna/", idType: URI) {
        PageHome {
          introContents {
            introContentTitle
            introContentDesc
            introContentCookName
            introContentButtonText
            introContentCookImg {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            introContentBg {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1440, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          aboutBreadBg {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          aboutBreadCookImg {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          aboutBreadCookName
          aboutBreadFooter
          aboutBreadText
          aboutBreadTitle
          videosBread {
            videoTitle
            videoUrl
            videoThumbnail {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 60) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          videosBreadButtonText
          aboutPizzaBg {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          aboutPizzaCookImg {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          aboutPizzaCookName
          aboutPizzaFooter
          aboutPizzaText
          aboutPizzaTitle
          videosPizza {
            videoTitle
            videoUrl
            videoThumbnail {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 60) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          videosPizzaButtonText
          productsIntro
          productsDesc
          productsPackageHeading
          productsPackageImg {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 600, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          productsShadow {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1400, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          productsRecipesImg {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 600, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          productsItems {
            productsItemName
            productsItemNumber
            productsItemImg {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          footerHeading
          footerText
          footerAuthors {
            footerAuthorHeading
            footerAuthorPage
            footerAuthorPageUrl
            footerAuthorText
            footerAuthorBg {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            footerAuthorLogo {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
