import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Loader from 'components/shared/Loader';
import LazyImage from 'components/shared/LazyImage';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import Button from 'components/shared/Button';

const VideosWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  border-bottom: 2px solid #888888;
  padding-bottom: 20px;
  gap: 20px;
  @supports not (gap: 20px) {
    & > div:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  ${({ theme }) => theme.mq.s} {
    gap: 40px;
  }
  ${({ theme }) => theme.mq.lg} {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 40px;
  }
  ${({ theme }) => theme.mq.xxl} {
    gap: 80px;
  }
`;

const VideoTitle = styled.p`
  font-weight: ${({ theme }) => theme.light};
  font-size: 2.2rem;
  margin-top: 15px;
  ${({ theme }) => theme.mq.s} {
    font-size: 2.8rem;
    margin-top: 20px;
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: 3.3rem;
    margin-top: 25px;
  }
`;

const Video = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Thumbnail = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  transition: 1s ease;
`;

const VideoInnerWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  &:after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }
  ${({ active }) =>
    !active &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        border-radius: 25px;
        border: 2px solid #fff;
        z-index: 2;
        transition: 0.35s;
      }
      &:hover {
        ${Thumbnail} {
          transform: scale(1.14);
        }
        path {
          fill: ${({ theme }) => theme.colors.gold};
        }
        circle {
          stroke: ${({ theme }) => theme.colors.gold};
        }
        &:before {
          border-color: ${({ theme }) => theme.colors.gold};
        }
      }
    `}
`;

const PlayButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const PlayButton = styled.button`
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  width: 74px;
  height: 74px;
  cursor: pointer;
  ${({ theme }) => theme.mq.s} {
    width: 100px;
    height: 100px;
  }
  & > svg {
    width: 100%;
    height: 100%;
    path,
    circle {
      transition: 0.35s;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 25px 0 0;
  ${({ theme }) => theme.mq.s} {
    padding: 40px 0 0;
  }
  button,
  a {
    background: ${({ theme }) => theme.colors.orange};
    border-color: ${({ theme }) => theme.colors.orange100};
    color: #fff;
  }
`;

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const About = ({ videos, buttonText, id }) => {
  const [playingVideo, setPlayingVideo] = useState(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const changeVideo = videoIndex => {
    if (videoIndex !== playingVideo) {
      setPlayingVideo(videoIndex);
      setVideoLoaded(false);
    }
  };

  return (
    <>
      {videos && (
        <Section id={id}>
          <Content>
            <VideosWrapper>
              {videos.map(({ videoURL, thumbnail, title }, index) => (
                <Video key={title}>
                  <VideoInnerWrapper
                    onClick={() => changeVideo(index)}
                    active={playingVideo === index && videoLoaded}
                  >
                    {playingVideo === index && (
                      <VideoWrapper>
                        <iframe
                          width="100%"
                          height="100%"
                          title={title}
                          src={`${videoURL}?autoplay=1`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          onLoad={() => setVideoLoaded(true)}
                        />
                      </VideoWrapper>
                    )}
                    {(!videoLoaded || playingVideo !== index) && (
                      <Thumbnail>
                        <LazyImage src={thumbnail} alt={title} cover />
                      </Thumbnail>
                    )}
                    {playingVideo === index ? (
                      <>{!videoLoaded && <Loader />}</>
                    ) : (
                      <PlayButtonWrapper>
                        <PlayButton>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="119.288"
                            height="119.288"
                            viewBox="0 0 119.288 119.288"
                          >
                            <g
                              id="Group_44"
                              data-name="Group 44"
                              transform="translate(-271.328 -126.528)"
                            >
                              <path
                                id="Path_16"
                                data-name="Path 16"
                                d="M365.164,186.172l-57.416-37.618v75.235Z"
                                fill="#fff"
                              />
                              <circle
                                id="Ellipse_8"
                                data-name="Ellipse 8"
                                cx="54.472"
                                cy="54.472"
                                r="54.472"
                                transform="translate(276.499 131.699)"
                                fill="none"
                                stroke="#fff"
                                strokeWidth="10.343"
                              />
                            </g>
                          </svg>
                        </PlayButton>
                      </PlayButtonWrapper>
                    )}
                  </VideoInnerWrapper>
                  <VideoTitle
                    dangerouslySetInnerHTML={{
                      __html: title,
                    }}
                  />
                </Video>
              ))}
            </VideosWrapper>
            <ButtonWrapper>
              <Button
                as="a"
                href="https://www.aledobre.pl/29894,Zestaw_mak_do_domowego_wypieku_chleba_pizzy_i_focacci_Ashanti_ToTa.html"
                target="_blank"
              >
                {buttonText}
              </Button>
            </ButtonWrapper>
          </Content>
        </Section>
      )}
    </>
  );
};

About.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.object).isRequired,
  buttonText: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default About;
